.App {
  font-family: sans-serif;
}

.TableEdiBtn {
  display: none;
  cursor: pointer;
}

.TableEdit:hover .TableEdiBtn {
  display: inline-block;
}
